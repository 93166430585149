import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  TextField
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CustomSearch = ({open, handleClose}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      maxWidth="sm"
      fullWidth
      aria-labelledby="custom-search-dialog"
    >
      <form method="GET" action="/search-results" autoComplete="off">
        <Box p={3} fontSize="h6.fontSize" fontWeight={400}>Search MechanicBuddy</Box>
        <DialogContent>
          <TextField
            autoFocus
            name="q"
            placeholder="What are you looking for?"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="secondary" variant="contained">
            Search
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default CustomSearch;
